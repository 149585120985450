// @ts-ignore
import wt48c from '../images/wt48c_c.png';
// @ts-ignore
import wt25 from '../images/widetek-25.png';
// @ts-ignore
import wt36 from '../images/wt36art.png';
// @ts-ignore
import bookeye4 from '../images/bookeye4v2.png';

export type Product = {
  applications: string[];
  characteristics: {
    technology: string;
    versatility: string;
    configuration: string;
    speed: string;
  };
  content: string;
  easeOfUse: string;
  embed: string;
  logo: string;
  markets: string[];
  media: any;
  principalCharacteristics: string;
  slug: string;
  technicalSpecs: string[];
  title: string;
};

const products: Product[] = [
  {
    applications: [
      'Escaneo de mapas.',
      'Escaneo de posters a color.',
      'Escaneo de planos y dibujos CAD.',
      'Escaneo de periodicos y revistas.',
      'Copia y archivo de planos azules y sepias.',
      'Escaneo de documentos de gran formato.'
    ],
    characteristics: {
      technology:
        'Tecnología de cámaras sin polvo, lámparas LED sin calentamiento, libre de IR / UV, con pantalla táctil WVGA de gran color. Tiene uAna resolución de hasta 1200 x 1200, con una velocidad de escaneo a 200 dpi\'s de 15" por segundo, con visualización en tiempo real de las imagenes escaneadas.',
      versatility:
        'Gracias a su arquitectura integrada de software, permite escanear directo a una unidad de red, a una unidad USB, a una impresora de red o bien directamente a un plotter, sin necesidad de tener una PC conetada, sus puertos USB y Ethernet, permiten una conectividad total del scanner.',
      configuration:
        'Gracias a las aplicaciones Scan2USB, Scan2Print y Scan2Network, que le permiten operar sin necesidad de una PC, su operación es inmediata. La tecnología Scan2Pad®, permite su operación via tableta mediante WiFi.',
      speed:
        'Su sistema de transporte "face up scanning", permiten ver lo que se está escaneando. Por su memoria, permite velocidades de escaneo de 15" por segundo a 200 dpi\'s, con una profundida de color 48 bits de color y 16 bits de escala de grises. '
    },
    content:
      'El escáner de planos CIS Color más rápido, ancho y de mayor calidad en el mercado. Hecho en Alemania a un precio asequible.',
    easeOfUse:
      'Simplemente desempaque el escáner, ensamble, encienda, asigne una IP en la red y podrá escanear hacia cualquier directorio de la red o tableta conectada mediante Wi-Fi',
    embed: `<iframe width="555" height="312" src="https://www.youtube.com/embed/S7CtH9F1_mE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
    logo: 'scanner.png',
    markets: [
      'Infraestructura e ingeniería.',
      'Arquitectura y construcción.',
      'Desarrolladores inmobiliarios y de infraestructura.',
      'Servicios de elaboración de mapas (GIS).',
      'Universidades, bibliotecas.',
      'Agencias de medios impresos.'
    ],
    media: wt48c,
    principalCharacteristics:
      'Permite la visualización de la imagen escaneada, gracias al monitor integrado y a su memoria, la cual permite el escaneo de planos de gran formato con un ancho de hasta 48" y un largo de 16 metros a 300 dpi\'s.',
    slug: 'widetek-48c',
    technicalSpecs: [
      'Ancho máximo de documento: 48',
      'Longitud máxima: 65 metros',
      'Grueso máximo: 2.5 milimetros',
      'Resolución del scanner: 1200 x 1200 dpi',
      'Profundidad de color: 48 bit color, 16 bit escala de gris',
      'Método de transporte: Cara arriba, entrada frontal',
      'Fuente de luz: 1350 LEDS por lado',
      'Camara: 4x modulos CIS modules con 29,852 pixeles',
      'Montaje camara: encapsulada a prueba de polvo'
    ],
    title: 'WideTEK 48C'
  },
  {
    title: 'WideTEK 25',
    slug: 'widetek-25',
    applications: [
      ' Escaneo de mapas, carteles en color, gráficos de visualización, ilustraciones o fotografías',
      'Escaneo de periódicos, publicaciones periódicas, catálogos y revistas',
      'Escaneo de borradores y publicidad en cartulina, fotos, telas y azulejos.',
      'Escaneo de superficie tridimensionales con control de salida de impresión Braille'
    ],
    embed:
      '<iframe width="555" height="312" src="https://www.youtube.com/embed/Iz7Qlj8AWc0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    markets: [
      'Arquitectura, Ingeniería, Construcción y CAD',
      'Servicios de reprografía de arte.',
      'Servicios de cartografía y GIS',
      'Control de medios, servicios de recortes de prensa',
      'Universidades, bibliotecas, y registros',
      'Inspección y control de calidad industrial '
    ],
    content:
      'El escáner de superficie plana DINA2, con unidad opcional de contraluz que le permite escanear rayos X con calidad de diagnóstico.',
    principalCharacteristics:
      'Gracias a su cama de digitalización de cristal anti-rayaduras y anti-reflejante, que se expande hasta los bordes, los documentos no se maltratan por doblajes o esquinas dañadas, junto con su velocidad de menos de 3 segundos por escaneo, lo hacen unico.',
    easeOfUse:
      'El escáner viene con una pantalla táctil integrada para su operación independiente, sin necesidad de una PC o de conexión con otro dispositivo para comenzar a operar el equipo.',
    characteristics: {
      configuration:
        'El escáner viene equipado con la tecnología Scan2Pad® lo que le permite su operación desde la pantalla táctil integrada o bien desde cualquier tableta conectada por WiFi. No requiere de equipo adicional para su configuración y operación.',
      speed:
        'Su velocidad de escanear un documento completo sobre su cama de 18.5 x 25 pulgadas o ISO/DIN A2 en menos de 3 segundos, al tiempo que permiten hacer una rotación de la imágen al momento lo hacen el más rápido del mercado.',
      technology:
        'Gracias a su doble lámpara LED y su tecnología de difusión de luz, permite eliminar sombras no deseadas en la imagen, obteniéndose imágenes nítidas incluso en el caso de objetos con textura.',
      versatility:
        'Permite el escaneo de una gran cantidad de objetos (documentos, textiles, grabados), y con el aditamento 650 backlight, permite escanear placas de rayos X con calidad de diagnóstico.'
    },
    media: wt25,
    technicalSpecs: [
      'Dimensiones de la cama plana:635 x 470 mm (25 x 18.5 pulgadas)',
      "Resolución óptica: 1,200 x 600 dpi's",
      'Profundidad de color: 48 bit a color, 16 bit escala de grises',
      'Formatos de salida: Multipágina PDF (PDF/A) y TIFF, JPEG, JPEG 2000, PNM, PNG, BMP, TIFF (Raw, G3, G4, LZW), AutoCAD DWF, JBIG, DjVu, DICOM, PCX, Postscript y EPS.'
    ],
    logo: 'scanner.png'
  },
  {
    title: 'WideTEK 36ART',
    applications: [
      'Reproducción de alta calidad de obras de arte para la venta en museos, archivos y casas de subastas',
      'Preservación de obras de arte como colecciones especiales en archivos, museos, agencias gubernamentales',
      'Presentación de obras de arte en catálogos públicos, privados, impresos, sitios web para la venta o publicidad',
      'Control de calidad industrial de materiales pesados, gruesos o húmedos texturados.',
      'Escaneo de ilustraciones para su visualización como medida de seguridad y antirrobo.'
    ],
    embed: '',
    markets: [
      'Museos, Galerías, Exposiciones',
      'Servicios de Copias, Reprografía',
      'Gobierno, Archivos, Colecciones Especiales',
      'Universidades, Bibliotecas, Propietarios de Bellas Artes',
      'Industria Textil y de Materiales de Construcción'
    ],
    slug: 'widetek-36art',
    content:
      'Escaner hecho para la reproducción de alta calidad de obras de arte, que preserva la calidad de los objetos, sin comprometer su estado físico, ideal para su uso en museos, galerías y casas de subastas.',
    logo: 'scanner.png',
    principalCharacteristics:
      'Gracias a su diseño especial para la digitalización sin contacto físico, es un equipo ideal para el escaneo de obras de arte tales como pinturas de oleo, acuarela, acrílicos, pastel o técnicas mixtas.',
    easeOfUse:
      'Para protección de los objetos, el escáner mueve las obras de arte debajo de los elementos de la cámara CCD, evitando el contacto mientras se obtiene una imagen perfecta del objeto.',
    media: wt36,
    characteristics: {
      configuration:
        'El escáner cuenta con una unidad de procesamiento incluída, lo que facilita su configuración y su operación, ya que cuenta con una pantalla táctil para llevar a cabo los pasos necesarios, de forma intuitiva y directamente en el equipo',
      speed:
        "Gracias a su tecnología permite la digitalización en formato plano de 42.2 mm a color por segundo a una resolución de 300 dpi's, o bien con textura en 3D a una velocidad de 21.1 mm por segundo a 300 dpi's.",
      technology:
        'Para brindar la mayor protección de obras de arte, el escáner cuenta con dos lámparas de LED con difusión de luz natural, que no emiten calor y son libres de rayos UV/IR, lo que preserva los pigmentos, colores y materiales.',
      versatility:
        "Por su tecnología libre de contacto, es un equipo ideal para escanear cuadros en oleo, acuarela, acrílico, lápiz, carboncillo, pastel o tecnicas mixtas, con una resolución de hasta 300 dpi's y tamaño de hasta 914 x 1524 mm."
    },
    technicalSpecs: [
      'Tamaño máximo del objeto: 914 mm x 1,524 mm',
      'Altura máxima del objeto: 100 mm',
      "Resolución óptica: 600 x 600 dpi's",
      'Profundidad de color: 48 bit color, 16 bit escala grises',
      'Formatos de salida: Multipágina PDF (PDF/A) y TIFF, JPEG, JPEG 2000, PNM, PNG, BMP, JBIG, EPS, PCX, TIFF (Raw, G3, G4, LZW) entre otros'
    ]
  },
  {
    title: 'Bookeye 4 V2',
    slug: 'bookeye4-v2',
    applications: [
      'Archivar documentos de agencias gubernamentales nacionales y locales, registros y organizaciones sin fines de lucro',
      'Exploración de periódicos, publicaciones periódicas, catálogos y revistas',
      'Digitalización de archivos desde carpetas de archivos',
      'Digitalización de documentos enlazados y grapados como contratos, cuentas y documentación',
      'Conservación de libros y documentos históricos sensibles'
    ],
    embed:
      '<iframe width="555" height="312" src="https://www.youtube.com/embed/2IbdREBedyI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
    markets: [
      'Organizaciones comerciales gubernamentales y privadas',
      'Bibliotecas',
      'Archivo',
      'Universidades',
      'Información Instalaciones'
    ],
    content:
      'Un escaner diseñado para la digitalización de libros, revistas y periódicos. Fabricado en Alemania con la tecnología más avanzada.',
    logo: 'scanner.png',
    principalCharacteristics:
      'El escáner Bookeye® 4 V2 Professional cuenta con una pantalla táctil para la operación del equipo, y una pantalla para visualización de las imágenes, cuenta con una cama de digitalización que puede ser colocada en forma de V (120 grados) para cuidado de los libros.',
    easeOfUse:
      'Cuenta con todo lo necesario para su operación de forma autónoma, sin necesidad de una PC. La pantalla táctil de 7", permite operar el equipo de forma sencilla y sus dos puertos USB, permiten conextar unidades de almacenamiento, o bien Ethernet de 1 GBit',
    characteristics: {
      configuration:
        'El Bookeye® 4 V2 Professional cuenta con todo lo necesario para su operación autónoma, ya que cuenta con unidad de procesamiento interna y pantalla tactil de 7" que permite operar el equipo de forma inmediata.',
      speed:
        "Gracias a su doble lámpara LED, permite imágenes de gran calidad, con un desempeño de menos de 3 segundos a resoluciones inferiores a 400 dpi's a color en tamaño DIN A2, con una salida de 24 bits a color.",
      technology:
        "La tecnología en el Bookeye® 4 V2 Professional permite el escaneo de libros hasta un tamaño A2 y cuenta con doble lámpara LED y corrección de curvas, eliminando las sombras por la curvatura de los libros, generando imágenes a 600 dpi's de gran calidad.",
      versatility:
        'El Bookeye® 4 V2 Professional es un equipo que permite escanear a color libros, revistas, periódicos y documentos empastados con un tamaño hasta A2. Por su cama ajustable a 120/180 grados, facilita el cuidad de libros delicados.'
    },
    technicalSpecs: [
      'Tamaño máximo aceptado: 460 x 620 mm',
      "Resolución escáner: 600 x 600 dpi's",
      'Profundidad de color: 48 bit color, 16 bit escala grises',
      'Formatos de salida: Multipágina PDF (PDF/A) y TIFF, JPEG, JPEG 2000, PNM, PNG, BMP, JBIG, EPS, PCX, TIFF (Raw, G3, G4, LZW) entre otros'
    ],
    media: bookeye4
  }
];

export default products;
