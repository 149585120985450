import React from 'react';
import Button from './Button';
import Card from './Card';
import { Link } from 'gatsby';
import { Product } from '../data/products';

type ProductCardProps = {
  product: Product;
};
const ProductCard = ({ product }: ProductCardProps) => (
  <Card className="flex flex-col h-full">
    <div className="flex items-center mt-6">
      <img className="w-12 mr-1" src={product.logo} alt={product.title} />
      <p className="text-xl font-semibold">{product.title}</p>
    </div>
    <p className="mt-6 mb-auto">{product.content}</p>
    <Link to={`/productos/${product.slug!}`}>
      <Button size="default" className="w-full mt-6">
        Ver Mas
      </Button>
    </Link>
  </Card>
);

export default ProductCard;
