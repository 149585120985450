import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../components/Button';
import ProductCard from '../components/ProductCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import products from '../data/products';

export const query = graphql`
  query {
    serviceimg1: file(relativePath: { eq: "1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceimg2: file(relativePath: { eq: "2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    serviceimg3: file(relativePath: { eq: "4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 542) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgimg: file(relativePath: { eq: "bg.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ({ data }) => (
  <Layout>
    <section className="relative w-full h-screen pt-20 overflow-hidden bg-primary-darker lg:h-full md:py-40">
      <div className="absolute top-0 z-10 w-full h-full overflow-hidden">
        <Img
          style={{
            filter: 'grayscale(1)',
            opacity: '25%'
          }}
          className="h-full"
          fluid={data.bgimg.childImageSharp.fluid}
          alt="bg"
        />
      </div>
      <div className="container relative z-20 px-8 mx-auto">
        <div className="text-center">
          <h1 className="space-y-1 text-5xl text-white leading-0 lg:font-semibold lg:text-5xl">
            Expertos en Gestión Documental
          </h1>
          <p className="mt-32 md:mt-12">
            <Link to="/contacto">
              <Button size="lg">Solicta tu demo</Button>
            </Link>
          </p>
        </div>
      </div>
    </section>

    <section id="stats" className="py-6 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Nuestros resultados</LabelText>
        <div className="flex flex-col mt-8 sm:flex-row lg:px-24">
          <div className="w-full sm:w-1/4">
            <StatsBox primaryText="16,000,000" secondaryText="De documentos" />
          </div>
          <div className="w-full sm:w-1/4">
            <StatsBox primaryText="19" secondaryText="Años en el mercado" />
          </div>
          <div className="w-full sm:w-1/4">
            <StatsBox primaryText="7" secondaryText="Industrias cubiertas" />
          </div>
          <div className="w-full sm:w-1/4">
            <StatsBox primaryText="8" secondaryText="Estados en la republica" />
          </div>
        </div>
      </div>
    </section>

    <SplitSection
      id="services"
      primarySlot={
        <div className="mt-6 lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight text-center lg:text-left">
            Gestión de archivos
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Uno de los activos mas importante de cualquier organización es la
            información de los clientes, activos fijos, contratos de personal,
            entre otros, los cuales a pesar de la transformación digital, se
            requieran deforma física y cuya administración debe garantizar su
            integridad, así como su correcto resguardo. En Document Imaging
            Company, nos tomamos muy en serio la administración de su
            información, nuestro equipo de profesionales se encargan de
            gestionar su archivo conforme a los estándares de control requeridos
            por su organización.
          </p>
        </div>
      }
      secondarySlot={
        <Img
          fluid={data.serviceimg1.childImageSharp.fluid}
          alt="Gestion de archivos"
        />
      }
    />

    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight text-center lg:text-left">
            Gestión documental
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Implementar una estrategia de gestión documental, es un reto que
            requiere de una adecuada planificación del proceso de transformación
            que implica pasar del uso del papel a la consulta en línea de
            documentos electrónicos. En Document Imaging Company hemos apoyado a
            muchos clientes a transformar sus procesos diarios, con los
            beneficios de una eficiente gestión documental de su información en
            línea, mejorando de forma significativa su productividad y
            contribuyendo a la transformación digital de la organización.
          </p>
        </div>
      }
      secondarySlot={
        <Img
          fluid={data.serviceimg2.childImageSharp.fluid}
          alt="Gestion de archivos"
        />
      }
    />

    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight text-center lg:text-left">
            Digitalización
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Hoy en día, el proceso de digitalizar documentos parece cosa de
            rutina; sin embargo, llevar a cabo un proceso de digitalización
            estandarizado a lo largo de la organización, requiere de un análisis
            sobre el cómo, porqué y para que digitalizo, y evitar de esta forma
            cambiar el papel físico por archivos digitales, pero sin aportar
            valor. Nuestro equipo de profesionales le ayudarán a plantear la
            forma mas eficiente de implementar procesos de digitalización que se
            ajusten a sus necesidades sin desperdiciar tiempo, dinero y energía
            en el trayecto.
          </p>
        </div>
      }
      secondarySlot={
        <Img
          fluid={data.serviceimg3.childImageSharp.fluid}
          alt="Digitalizacion"
        />
      }
    />

    <section id="productos" className="py-12 lg:py-24">
      <div className="container mx-auto">
        <LabelText className="mb-6 text-center text-gray-600">
          Productos
        </LabelText>
        <div className="flex flex-col md:flex-row">
          {products.map(product => (
            <div key={product.title} className="flex-1 mb-6 lg:px-3 lg:mb-8">
              <ProductCard product={product} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section className="container py-24 mx-auto my-20 text-center lg:rounded-lg bg-primary-lighter">
      <h3 className="text-4xl font-semibold text-white lg:text-5xl">
        Impulsando la digitalización de documentos.
      </h3>
      <p className="mt-8 text-xl text-gray-200 lg:mx-32">
        Contamos con historias de éxito en el ámbito público y privado en
        proyectos de digitalización y gestión de información, así como el apoyo
        de fabricantes de software y de hardware líderes de la industria.
      </p>
      <p className="mt-8 ">
        <Link to="/contacto">
          <Button size="xl">Solicita tu Demo</Button>
        </Link>
      </p>
    </section>
  </Layout>
);
