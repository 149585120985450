import React from 'react';

export type CardProps = {
  className?: string;
};
const Card: React.FC<CardProps> = ({ className, children }) => (
  <div
    className={`p-8 bg-white shadow-xl rounded-lg border border-solid border-gray-200 ${className}`}
  >
    {children}
  </div>
);

export default Card;
